import React from 'react';
import ReactDOM from 'react-dom/client'; // Import createRoot from React 18
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import configureStore from './configureStore';
import { history } from './utilities/history';
import App from './App';
import './i18n';
import * as serviceWorker from './serviceWorker';

export const store = configureStore();

const MOUNT_NODE = document.getElementById('root');
// Initialize the root using createRoot
const root = ReactDOM.createRoot(MOUNT_NODE);

const render = Component => {
  root.render(
    <Provider store={store}>
      <Router history={history}>
        <Component />
      </Router>
    </Provider>,
    MOUNT_NODE
  );
};
render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// eslint-disable-next-line
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', () => {
    render(App);
  });
}
serviceWorker.unregister();
