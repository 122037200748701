//@flow
import React, { Component, type ComponentType } from 'react';
import PasswordValidation from './PasswordValidation';
import {
  InputDiv,
  DisplayUnitsP,
  InputValidationSmall,
  InputLabel,
  StyledInput,
  StyledInlineInput,
  InputInlineLabel,
  IconWrapperDiv
} from './InputStyles';
import { type Dispatch } from 'redux';
import { type ReactObjRef } from '../utilities/types';

export type Meta = {
  active?: boolean,
  asyncValidating?: boolean,
  autofilled?: boolean,
  dirty?: boolean,
  dispatch?: Dispatch<*>,
  error?: any,
  form?: string,
  initial?: any,
  invalid?: boolean,
  pristine?: boolean,
  submitting?: boolean,
  submitFailed?: boolean,
  touched?: boolean,
  valid?: boolean,
  visited?: boolean,
  warning?: any
};

type Props = {|
  input: {|
    checked?: boolean,
    name: string,
    onBlur?: { (eventOrValue: Event | any): void },
    onChange?: { (eventOrValue: Event | any): void },
    onDrop?: { (event: Event): void },
    onDragStart?: { (event: Event): void },
    onFocus?: { (event: Event): void },
    value: any
  |},
  meta: Meta,
  type?: string,
  label: string,
  disabled?: boolean,
  passwordValidation?: boolean,
  displayUnits?: string,
  showNumberWheel?: number,
  touchOnChange?: boolean,
  placeholder?: string,
  step?: number,
  inlineLabel?: boolean,
  readOnly?: boolean,
  readOnlyLabel?: string,
  readOnlyValue?: string,
  icon?: ComponentType<any>,
  iconProps?: {},
  disabledErrorMargin?: boolean,
  hasPlaceholder?: boolean,
  inputFontSize?: string,
  dataCy?: string
|};

class Input extends Component<Props> {
  static defaultProps = {
    disabled: false,
    label: '',
    passwordValidation: false,
    type: 'text',
    meta: {},
    iconProps: {},
    dataCy: 'input-field'
  };

  inputRef: ReactObjRef<'input'> = React.createRef();

  focus = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  render() {
    const Icon = this.props.icon;
    const { meta, input, displayUnits } = this.props;
    const labelText = this.props.readOnly
      ? this.props.readOnlyLabel || this.props.label
      : this.props.label;
    const value = this.props.readOnly
      ? this.props.readOnlyValue || this.props.input.value
      : this.props.input.value;
    return (
      <InputDiv {...this.props}>
        {this.props.inlineLabel && (
          <>
            <InputInlineLabel {...this.props} {...input} value={value}>
              {this.props.inlineLabel}
            </InputInlineLabel>
            <StyledInlineInput
              {...this.props}
              {...input}
              value={value}
              type={this.props.type}
              disabled={this.props.disabled}
              onChange={
                this.props.touchOnChange ? input.onBlur : input.onChange
              }
              placeholder={this.props.placeholder}
              step={this.props.step}
            />
          </>
        )}
        {(this.props.label || this.props.hasPlaceholder) && (
          // $FlowFixMe
          <>
            <InputLabel
              {...this.props}
              {...input}
              value={value}
              inactiveFontSize={this.props.inputFontSize}
            >
              {labelText}
            </InputLabel>
            <StyledInput
              {...this.props}
              {...input}
              value={value}
              type={this.props.type}
              disabled={this.props.disabled}
              onChange={
                this.props.touchOnChange ? input.onBlur : input.onChange
              }
              placeholder={this.props.placeholder}
              step={this.props.step}
              readOnly={this.props.readOnly}
              disabledErrorMargin={this.props.disabledErrorMargin}
              fontSize={this.props.inputFontSize}
              ref={this.inputRef}
              data-cy={this.props.dataCy}
            />
            {Icon && (
              <IconWrapperDiv>
                <Icon {...this.props.iconProps} />
              </IconWrapperDiv>
            )}
          </>
        )}
        {displayUnits && <DisplayUnitsP>{displayUnits}</DisplayUnitsP>}
        {this.props.passwordValidation ||
          this.props.readOnly ||
          (meta.touched && meta.error && (
            <InputValidationSmall data-cy="input-error" {...this.props}>
              {meta.error}
            </InputValidationSmall>
          ))}
        {this.props.passwordValidation && meta.touched && (
          <PasswordValidation {...meta} />
        )}
      </InputDiv>
    );
  }
}

export default (Input: React$ComponentType<Props>);
