// @flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import {
  ConfirmationModalButtons,
  ConfirmationModalDiv,
  SuccessModalA,
  ConfirmationModalContentDiv,
  SuccessLottieDiv
} from './ModalConductorStyles';
import Lottie from '../../components/Lottie';
export type SuccessModalPayload = {
  title: string,
  message: string,
  onConfirm?: () => void,
  confirmText?: string,
  lottie?: string,
  t: TFunction
};
type Props = {
  closeModal: () => void,
  ...$Exact<SuccessModalPayload>
};

class SuccessModal extends Component<Props> {
  confirm = () => {
    this.props.closeModal();
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  };
  render() {
    return (
      <ConfirmationModalDiv>
        <ConfirmationModalContentDiv data-cy="success-modal">
          <h4 data-cy="modal-title">{this.props.title}</h4>
          <p>{this.props.message}</p>
          <SuccessLottieDiv>
            {this.props.lottie && (
              <Lottie name={`${this.props.lottie}`} playOnScroll />
            )}
          </SuccessLottieDiv>
        </ConfirmationModalContentDiv>
        <ConfirmationModalButtons>
          <SuccessModalA onClick={this.confirm}>
            {this.props.confirmText || this.props.t('actions.ok')}
          </SuccessModalA>
        </ConfirmationModalButtons>
      </ConfirmationModalDiv>
    );
  }
}
export default withTranslation(['global'])(SuccessModal);
