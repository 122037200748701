const capitalizeWord = (word) => {
  // Accepts a word and returns the capitalized version
  const normalizeWord = word.toLowerCase();
  const firstLetter = normalizeWord[0].toUpperCase();
  const remainingLetters = normalizeWord.slice(1);

  return firstLetter + remainingLetters;
};

// Use formatKeysForI18n when keys returned from backend are being string interpolated for translations.
const formatKeysForI18n = (key) => {
  // Accepts a key and returns it in camelCase format
  // i.e. sales_and_support => salesAndSupport
  if (!key) return;

  const wordsFromKey = key.split(/[\s-_:]/).filter((word) => word !== '');
  let formattedKey = '';
  for (let i = 0; i < wordsFromKey.length; i++) {
    if (i === 0) {
      formattedKey += wordsFromKey[i].toLowerCase();
    } else {
      formattedKey += capitalizeWord(wordsFromKey[i]);
    }
  }

  return formattedKey;
};

const formatText = (text, opts = {}) => {
  if (!text || typeof text !== 'string') {
    return text; // Return as-is if text is falsy or not a string
  }
  // Set defaults explicitly for options
  const { casing = 'keep', spaces = 'keep' } = opts;

  let formattedText = text;

  // Handle spaces based on the 'spaces' option
  if (spaces === 'all') {
    formattedText = formattedText.replace(/\s/g, '');
  } else if (spaces === 'trim') {
    formattedText = formattedText.trim();
  }
  // If spaces is 'keep', do nothing

  // Handle casing based on the 'casing' option
  if (casing === 'uppercase') {
    formattedText = formattedText.toUpperCase();
  } else if (casing === 'lowercase') {
    formattedText = formattedText.toLowerCase();
  }
  // If 'casing' is 'keep', do nothing

  return formattedText;
};

export { formatKeysForI18n, formatText };
