//@flow
import React from 'react';
import { TFunction } from 'react-i18next';
import withDropdown from '../withDropdown';
import { OptionLi, OptionUl, OptionsWrapper } from './styles';

type Data = {
  text: string,
  value: string
};

type Props = {
  Select: (toggleDropdown: Function, t: Function) => any,
  Options?: Node,
  optionData: Array<Data>,
  t: TFunction,
  activeOption: Data,
  height: number
} & HOCProps;

type HOCProps = {
  isDropdownActive: boolean,
  toggleDropdown: () => void,
  setSelectedOption: (option: any) => void
};

/**
 * @description
 *
 * /v1/ OtherDropdowns to create:
 * language dropdown
 * filter dropdown
 * form dropdown
 */

function PageDropdown({
  Select,
  Options,
  optionData,
  isDropdownActive,
  toggleDropdown,
  setSelectedOption,
  t,
  height,
  activeOption
}: Props) {
  return (
    <>
      <Select
        toggleDropdown={toggleDropdown}
        t={t}
        activeOption={activeOption}
        isDropdownActive={isDropdownActive}
      >
        <OptionsWrapper dropdownActive={isDropdownActive} height={height}>
          {Options || (
            <OptionUl>
              {isDropdownActive &&
                optionData.map((option, i) => {
                  return (
                    <OptionLi
                      data-cy={`option-${option.text?.toLowerCase()}`}
                      key={option.text}
                      last={optionData.length === i + 1}
                      dropdownActive={isDropdownActive}
                      onClick={() => setSelectedOption(option)}
                    >
                      {option.text}
                    </OptionLi>
                  );
                })}
            </OptionUl>
          )}
        </OptionsWrapper>
      </Select>
    </>
  );
}

export default withDropdown(PageDropdown);
