import { fork, all } from 'redux-saga/effects';
import { logoutSaga } from './App/SignIn/sagas';
import {
  refreshSession,
  loadAppSaga,
  referralCookieSaga,
  setLocaleSaga
} from './App/sagas';
import {
  deleteDevice,
  blockDevice,
  unblockDevice
} from './App/PosTerminals/sagas';
import {
  transactionSaga,
  throttledTransactionSaga,
  transactionToggleSaga,
  markCompleteSaga,
  handleRefundInProgressSaga,
  debounceTransactionSaga,
  throttledTransactionCsvSaga,
  loadWalletBalancesSaga,
  markCompleteRefundSaga
} from './App/Transactions/sagas';
import {
  activateHpPlugin,
  deactivateHpPlugin
} from './App/HostedPayments/sagas';
import { deletePlugin } from './App/EcommercePlugins/sagas';
import {
  loadGroupsSaga,
  loadUsersSaga,
  deleteUserSaga
} from './App/UsersGroupsSettings/sagas';
import {
  payoutsSaga,
  loadPendingManualPayouts,
  loadNewPayoutData,
  throttlePayoutsSaga
} from './App/Payouts/sagas';
import { loadFeesSaga } from './App/Fees/sagas';
import { loadMerchantApplicationSaga } from './App/Verification/sagas';
import { loadSettlementOptionsSaga } from './App/SettlementOptions/sagas';
import {
  loadProfileDetails,
  loadCompanyContactSaga,
  selectTwoFactorMethodSaga,
  requestAuthCodeSaga,
  loadUserPromoSaga
} from './App/Company/sagas';
import {
  loadAssetBalancesSaga,
  loadDepositAddressSaga,
  loadUsageDetailsSaga
} from './App/AssetManagement/sagas';
import { loadAvailableAssetsSaga } from './App/AssetManagement/AcceptedCurrencies/sagas';
import {
  loadChartDataSaga,
  loadDashboardTransactionsSaga,
  loadBusinessInsightsSaga,
  loadNotificationsSaga
} from './App/Dashboard/sagas';
import { loadAssetManagementTransactionsSaga } from './App/AssetManagement/TransactionHistory/sagas';
import { confirmEmailTokenSaga } from './App/ConfirmEmail/sagas';
import { acceptRefundTokenSaga } from './App/AcceptRefund/sagas';
import {
  loadBillingInvoicesSaga,
  downloadInvoiceSaga
} from './App/Billing/sagas';
import { loadRefundsSaga, loadManageRefundDataSaga } from './App/Refunds/sagas';
import { sidebarToggleSaga } from './modules/Layout/Sidebar/sagas';
import { verifyAuthTokenSaga } from './App/VerifyAuth/sagas';
import { loadMarketingFilesSaga, marketingSaga } from './App/Marketing/sagas';
// import { setBannerMessagesSaga } from './modules/BannerMessages/sagas';
import {
  loadRecipientsSaga,
  deleteRecipient,
  loadRecipient
} from './App/Invoices/Recipients/sagas';
import {
  loadInvoice,
  resendInvoiceSaga,
  cancelInvoiceSaga,
  reactivateInvoiceSaga,
  markCompleteInvoiceSaga
} from './App/Invoices/Invoices/Show/sagas';
import { loadMerchantDocumentCollectionSaga } from './App/MerchantDocumentCollection/sagas';
import {
  cancelRecurringBillingSaga,
  recurringInvoicesSaga,
  recurringInvoicesSearchSaga
} from './App/Invoices/Recurring/Index/sagas';
import {
  invoicesRouteSaga,
  invoicesSaga,
  invoicesSearchSaga
} from './App/Invoices/Invoices/Index/sagas';
import { recurringInvoiceSaga } from './App/Invoices/Recurring/Show/sagas';
import { loadInvoiceCurrenciesSaga } from './App/Invoices/Invoices/New/sagas';

const sagas = [
  loadAppSaga,
  logoutSaga,
  refreshSession,
  deleteDevice,
  blockDevice,
  unblockDevice,
  activateHpPlugin,
  deactivateHpPlugin,
  deletePlugin,
  loadUsersSaga,
  loadGroupsSaga,
  loadFeesSaga,
  transactionSaga,
  transactionToggleSaga,
  debounceTransactionSaga,
  throttledTransactionCsvSaga,
  throttledTransactionSaga,
  referralCookieSaga,
  loadDashboardTransactionsSaga,
  loadChartDataSaga,
  payoutsSaga,
  throttlePayoutsSaga,
  loadPendingManualPayouts,
  loadNewPayoutData,
  loadCompanyContactSaga,
  sidebarToggleSaga,
  verifyAuthTokenSaga,
  // transactionSearchSaga,
  loadMerchantApplicationSaga,
  loadMarketingFilesSaga,
  marketingSaga,
  loadSettlementOptionsSaga,
  loadProfileDetails,
  downloadInvoiceSaga,
  loadAssetBalancesSaga,
  loadAvailableAssetsSaga,
  loadDepositAddressSaga,
  loadAssetManagementTransactionsSaga,
  markCompleteSaga,
  markCompleteRefundSaga,
  handleRefundInProgressSaga,
  loadUsageDetailsSaga,
  loadBusinessInsightsSaga,
  loadWalletBalancesSaga,
  confirmEmailTokenSaga,
  acceptRefundTokenSaga,
  loadBillingInvoicesSaga,
  deleteUserSaga,
  loadRefundsSaga,
  loadManageRefundDataSaga,
  selectTwoFactorMethodSaga,
  requestAuthCodeSaga,
  // setBannerMessagesSaga,
  invoicesSaga,
  invoicesSearchSaga,
  invoicesRouteSaga,
  loadRecipientsSaga,
  deleteRecipient,
  loadRecipient,
  loadInvoice,
  resendInvoiceSaga,
  cancelInvoiceSaga,
  reactivateInvoiceSaga,
  cancelRecurringBillingSaga,
  markCompleteInvoiceSaga,
  loadUserPromoSaga,
  loadMerchantDocumentCollectionSaga,
  setLocaleSaga,
  recurringInvoicesSaga,
  recurringInvoiceSaga,
  recurringInvoicesSearchSaga,
  loadInvoiceCurrenciesSaga,
  loadNotificationsSaga
];

function* globalSagas() {
  const globalSagasForks = sagas.map(saga => fork(saga));

  yield all(globalSagasForks);
}

export default globalSagas;
