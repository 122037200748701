//@flow
import React, { useState } from 'react';
import { history } from '../../../utilities/history';
import FilledDropdown from '../../Dropdown/PageDropdown/FilledDropdown';
import { Default, Mobile } from '../../Responsive';
import { H5, Tab, Wrapper } from './PrimaryStyles';
// $FlowFixMe This import exists... not sure why bugging out
import { useLocation } from 'react-router-dom';
import { formatText } from '../../../utilities/stringFormatting';

type Props = {
  data: Array<{ text: string, value: string }>,
  initialTab?: { text: string, value: string },
  handleClickOption?: (option: any) => void,
  link?: boolean,
  isStorybook?: boolean,
  className: string
};

/**
 * @description
 * Primary Tab Bar component >= <Default/> viewport
 * FilledDropdown component <= <Default/> viewport
 *
 * Can be used as a react router navLink if provided the link boolean and routes as values in the data object
 *
 */

export default function PrimaryTabBar({
  data,
  initialTab,
  handleClickOption,
  link,
  isStorybook,
  className
}: Props) {
  let location;
  if (!isStorybook) {
    location = useLocation();
  }

  const [active, setActive] = useState<{ text: string, value: string }>(
    location?.state?.option || initialTab || data[0]
  );

  const handleClick = (option) => {
    if (option === active) return;

    setActive(option);
    if (link) {
      return history.push(option.value, { option });
    }

    if (handleClickOption) {
      return handleClickOption(option);
    }
  };

  return (
    <>
      <Default>
        <Wrapper className={className}>
          {data.map((d, i) => (
            <Tab
              data-cy={`${formatText(d.text, {
                casing: 'lowercase',
                spaces: 'all'
              })}-primary-tab`}
              onClick={() => handleClick(d)}
              active={active.value === d.value}
              first={i === 0}
              last={i + 1 === data.length}
              key={i}
              length={data.length}
            >
              <H5 active={active.value === d.value}>{d.text}</H5>
            </Tab>
          ))}
        </Wrapper>
      </Default>
      <Mobile>
        <FilledDropdown
          className={className}
          optionData={data}
          handleClickOption={handleClick}
          initialOption={active}
        />
      </Mobile>
    </>
  );
}
