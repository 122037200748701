// @flow
import * as React from 'react';
import {
  CheckboxDiv,
  CheckboxContainer,
  CheckboxLabel,
  CheckmarkIcon,
  ValidationSmall
} from './CheckboxInputStyles';

type Props = {
  input: {
    onChange: (value: any) => void,
    value: boolean
  },
  meta?: { error?: string, touched?: boolean },
  label?: string,
  disabled?: boolean,
  name?: string,
  onValue?: any,
  offValue?: any,
  renderLabel: () => React.Node,
  readOnly?: boolean,
  className?: string
};

export default class CheckboxInput extends React.Component<Props> {
  static defaultProps = {
    onValue: true,
    offValue: false
  };
  renderError = () =>
    this.props.meta &&
    this.props.meta.touched &&
    this.props.meta.error && (
      <ValidationSmall>{this.props.meta.error}</ValidationSmall>
    );

  handleClick = () => {
    if (this.props.disabled || this.props.readOnly) {
      return;
    }
    let newValue = this.props.onValue;
    if (this.props.input.value === newValue) {
      newValue = this.props.offValue;
    }
    this.props.input.onChange(newValue);
  };

  render() {
    const checked = this.props.input.value === this.props.onValue;
    return (
      <>
        <CheckboxContainer className={this.props.className}>
          <input
            type="checkbox"
            name={this.props.name}
            value={checked}
            disabled={this.props.disabled || this.props.readOnly}
            id={this.props.name}
          />
          <CheckboxDiv
            disabled={this.props.disabled}
            readOnly={this.props.readOnly}
            onClick={this.handleClick}
            checked={checked}
            data-cy="checkbox-div"
          >
            {checked && <CheckmarkIcon name="checkmark" />}
          </CheckboxDiv>
          {(Boolean(this.props.label) || this.props.renderLabel) && (
            <CheckboxLabel htmlFor={this.props.name} onClick={this.handleClick}>
              {this.props.label}
              {this.props.renderLabel && this.props.renderLabel()}
            </CheckboxLabel>
          )}
        </CheckboxContainer>
        {this.renderError()}
      </>
    );
  }
}
