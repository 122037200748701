// @flow
import { supportedLanguages } from '../i18n';
import { type User, type PaymentTolerance } from '../utilities/types';
import {
  UPDATE_BANK_INFO,
  UPDATE_USER_TWO_FACTOR_METHOD
} from './Company/reducer';
type Action = { type: string, [key: string]: any };

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
export const SET_USER = 'SET_USER';
export const SET_CONFIRMED_AT = 'SET_CONFIRMED_AT';
export const LOGOUT = 'LOGOUT';
export const REFRESH_SESSION = 'REFRESH_SESSION';
export const SKIP_LOAD = 'SKIP_LOAD';
export const ROUTE_CHANGED = 'ROUTE_CHANGED';
export const SET_BRAND = 'SET_BRAND';
export const UPDATE_ACCEPTED_CURRENCIES = 'UPDATE_ACCEPTED_CURRENCIES';
export const UPDATE_PAYMENT_TOLERANCE = 'UPDATE_PAYMENT_TOLERANCE';
export const TOGGLE_MOBILE_NAVBAR = 'TOGGLE_MOBILE_NAVBAR';
export const SET_APPLICATION_SUBMITTED = 'SET_APPLICATION_SUBMITTED';
export const HANDLE_APP_LOAD = 'HANDLE_APP_LOAD';
export const SET_TENANT = 'SET_TENANT';
export const SHOW_TOOLTIP = 'SHOW_TOOLTIP';
export const HIDE_TOOLTIP = 'HIDE_TOOLTIP';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const CLOSE_CONTENT_TOOLTIP = 'CLOSE_CONTENT_TOOLTIP';
export const HANDLE_CONTENT_TOOLTIP = 'HANDLE_CONTENT_TOOLTIP';
export const SET_REFERRAL_COOKIE = 'SET_REFERRAL_COOKIE';
export const SET_APP_CONFIG = 'SET_APP_CONFIG';
export const SET_BANNER_MESSAGES = 'SET_BANNER_MESSAGES';
export const CLEAR_BANNER_MESSAGE = 'CLEAR_BANNER_MESSAGE';
export const SET_ACKNOWLEDGE_CONDITIONAL_APPROVAL =
  'SET_ACKNOWLEDGE_CONDITIONAL_APPROVAL';
export const TOGGLE_LANGUAGE_DROPDOWN = 'TOGGLE_LANGUAGE_DROPDOWN';
export const HANDLE_OUTSIDE_CLICK = 'HANDLE_OUTSIDE_CLICK';
export const SET_LANGUAGE_DATA = 'SET_LANGUAGE_DATA';
const SET_ACTIVE_ACCOUNT_TAB = 'SET_ACTIVE_ACCOUNT_TAB';
const SET_ACTIVE_SETTINGS_TAB = 'SET_ACTIVE_SETTINGS_TAB';
const SET_ACTIVE_MARKETING_TAB = 'SET_ACTIVE_MARKETING_TAB';

export const setActiveAccountTab = (activeAccountTab: {
  value: string,
  text: string
}) => ({
  type: SET_ACTIVE_ACCOUNT_TAB,
  activeAccountTab
});

export const setActiveSettingsTab = (activeSettingsTab: {
  value: string,
  text: string
}) => ({
  type: SET_ACTIVE_SETTINGS_TAB,
  activeSettingsTab
});
export const setActiveMarketingTab = (activeMarketingTab: {
  value: string,
  text: string
}) => ({
  type: SET_ACTIVE_MARKETING_TAB,
  activeMarketingTab
});
export const setLanguageData = (languageData: {
  locale: string,
  language: string
}) => ({
  type: SET_LANGUAGE_DATA,
  languageData
});

export const toggleLanguageDropDown = (clickedOutside: boolean) => {
  return clickedOutside
    ? { type: HANDLE_OUTSIDE_CLICK }
    : { type: TOGGLE_LANGUAGE_DROPDOWN };
};

export const clearBannerMessage = (id: number) => ({
  type: CLEAR_BANNER_MESSAGE,
  id
});
export const setBannerMessages = (payload: {}) => ({
  type: SET_BANNER_MESSAGES,
  payload
});

type Config = {
  apiBaseUrl: string,
  portalBaseUrl: string
};
export const setAppConfig = (config: Config) => ({
  type: SET_APP_CONFIG,
  config
});
export const updatePaymentTolerance = (payload: PaymentTolerance) => ({
  type: UPDATE_PAYMENT_TOLERANCE,
  payload
});
export const setReferralCookie = (code: string) => ({
  type: SET_REFERRAL_COOKIE,
  code
});
export const setTenant = (payload: {}) => ({
  type: SET_TENANT,
  payload
});
export const handleAppLoad = () => ({
  type: HANDLE_APP_LOAD
});
export const setApplicationSubmitted = () => ({
  type: SET_APPLICATION_SUBMITTED
});
export const routeChanged = (from: string, to: string) => ({
  type: ROUTE_CHANGED,
  from,
  to
});
export const skipLoad = () => ({
  type: SKIP_LOAD
});
export const setBrand = (brandLogo: string) => ({
  type: SET_BRAND,
  brandLogo
});
export const refreshSession = () => ({
  type: REFRESH_SESSION
});
export const logout = () => ({
  type: LOGOUT
});
export const setUser = (user: User): Action => ({
  type: SET_USER,
  user
});
export const setConfirmedAt = (confirmedAt: Date) => ({
  type: SET_CONFIRMED_AT,
  confirmedAt
});
export const setInitialState = (payload: {}): Action => ({
  type: SET_INITIAL_STATE,
  payload
});
export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR
});

export const toggleMobileNavbar = () => ({
  type: TOGGLE_MOBILE_NAVBAR
});

export const showTooltip = ({
  title,
  top,
  left
}: {
  title: string,
  top: number,
  left: number
}) => ({
  type: SHOW_TOOLTIP,
  title,
  top,
  left
});

export const hideTooltip = () => ({
  type: HIDE_TOOLTIP
});
export const setUserType = (userType: string) => ({
  type: SET_USER_TYPE,
  userType
});

export const closeContentTooltip = () => ({
  type: CLOSE_CONTENT_TOOLTIP
});

export const handleContentTooltip = (tooltipContentActive: string) => ({
  type: HANDLE_CONTENT_TOOLTIP,
  tooltipContentActive
});

export const setAcknowledgeConditionalApproval = () => ({
  type: SET_ACKNOWLEDGE_CONDITIONAL_APPROVAL
});

const initialState = {
  // view state
  loading: true,
  sidebarToggle: window.innerWidth >= 1024,
  userType: 'desktop',
  mobileNavBarToggle: false,
  tooltip: null,
  tooltipContentActive: '',
  // user state
  user: null,
  currencies: undefined,
  permissions: undefined,
  filters: undefined,
  acceptedCurrencies: undefined,
  brandLogo: '',
  primaryBrandColor: '#4a90e2',
  // tenant state
  companyName: 'NetCents',
  isWhiteLabel: false,
  tenant: {
    host: 'merchant.net-cents.com',
    apiHost: 'api.net-cents.com',
    termsOfServiceUrl: 'https://net-cents.com/terms/'
  },
  platformLogo: undefined,
  platformLogoSmall: undefined,
  favicon: undefined,
  config: {},
  bannerMessages: [],
  bannerMessagesSet: false,
  language: {
    languageDropDownOpen: false,
    supportedLanguages,
    activeLanguage: {}
  }
};

export default (state: typeof initialState = initialState, action: Action) => {
  switch (action.type) {
    case SKIP_LOAD:
      return {
        ...state,
        loading: false
      };
    case SET_INITIAL_STATE:
      return {
        ...state,
        loading: false,
        permissions: action.payload.permissions,
        filters: action.payload.filters,
        currencies: action.payload.currencies,
        acceptedCurrencies: action.payload.accepted_currencies,
        brandLogo: action.payload.brand.logo,
        paymentTolerance: action.payload.payment_tolerances,
        hasKycThreshold: action.payload.has_kyc_threshold,
        kycThresholdAmount: action.payload.kyc_threshold_amount,
        promo: action.payload.promo
      };
    case SET_BRAND:
      return {
        ...state,
        brandLogo: action.brandLogo
      };
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarToggle: !state.sidebarToggle,
        tooltip: null
      };
    case SET_USER:
      return {
        ...state,
        user: action.user
      };
    case SET_CONFIRMED_AT:
      return {
        ...state,
        user: {
          ...state.user,
          confirmed_at: action.confirmedAt
        }
      };
    case SET_APPLICATION_SUBMITTED:
      return {
        ...state,
        user: {
          ...state.user,
          application_submitted: true
        }
      };
    case LOGOUT:
      return {
        ...initialState,
        loading: false,
        tenant: state.tenant,
        companyName: state.companyName,
        isWhiteLabel: state.isWhiteLabel,
        platformLogo: state.platformLogo,
        primaryBrandColor: state.primaryBrandColor,
        brandLogo: state.brandLogo,
        platformLogoSmall: state.platformLogoSmall,
        favicon: state.favicon,
        bannerMessagesSet: false,
        language: {
          ...state.language
        }
      };
    case TOGGLE_MOBILE_NAVBAR:
      return {
        ...state,
        mobileNavBarToggle: !state.mobileNavBarToggle
      };
    case ROUTE_CHANGED:
      return {
        ...state,
        mobileNavBarToggle: false
      };
    case SET_TENANT:
      if (action.payload) {
        return {
          ...state,
          companyName: action.payload.company_name,
          platformLogo: action.payload.logo,
          platformLogoSmall: action.payload.small_logo,
          favicon: action.payload.small_logo,
          tenant: {
            host: action.payload.host,
            apiHost: action.payload.api_host,
            termsOfServiceUrl: action.payload.terms_of_service_url
          },
          primaryBrandColor: action.payload.primary_brand_colour,
          isWhiteLabel: true
        };
      }
      return state;
    case UPDATE_ACCEPTED_CURRENCIES: {
      return {
        ...state,
        acceptedCurrencies: action.payload
      };
    }
    case UPDATE_PAYMENT_TOLERANCE: {
      return {
        ...state,
        paymentTolerance: action.payload
      };
    }
    case UPDATE_BANK_INFO:
      return {
        ...state,
        user: {
          ...state.user,
          has_depository_bank: true
        }
      };
    case UPDATE_USER_TWO_FACTOR_METHOD:
      return {
        ...state,
        user: {
          ...state.user,
          two_factor_method: action.authType
        }
      };
    case SHOW_TOOLTIP:
      return {
        ...state,
        tooltip: {
          ...state.tooltip,
          title: action.title,
          top: action.top,
          left: action.left
        }
      };
    case HIDE_TOOLTIP:
      return {
        ...state,
        tooltip: null
      };
    case SET_USER_TYPE:
      return {
        ...state,
        userType: action.userType
      };
    case HANDLE_CONTENT_TOOLTIP:
      return {
        ...state,
        tooltipContentActive: action.tooltipContentActive
      };
    case CLOSE_CONTENT_TOOLTIP:
      return {
        ...state,
        tooltipContentActive: ''
      };
    case SET_APP_CONFIG:
      return {
        ...state,
        config: action.config
      };
    case SET_BANNER_MESSAGES:
      return {
        ...state,
        bannerMessages: action.payload,
        bannerMessagesSet: true
      };
    case CLEAR_BANNER_MESSAGE:
      return {
        ...state,
        // $FlowFixMe
        bannerMessages: state.bannerMessages.filter((m) => m.id !== action.id)
      };
    case SET_ACKNOWLEDGE_CONDITIONAL_APPROVAL:
      return {
        ...state,
        user: {
          ...state.user,
          acknowledged_conditional_approval: true
        },
        loading: false
      };
    case SET_LANGUAGE_DATA:
      return {
        ...state,
        language: {
          ...state.language,
          activeLanguage: action.languageData
        }
      };
    case TOGGLE_LANGUAGE_DROPDOWN:
      return {
        ...state,
        language: {
          ...state.language,
          languageDropDownOpen: !state.language.languageDropDownOpen
        }
      };
    case HANDLE_OUTSIDE_CLICK:
      return {
        ...state,
        language: {
          ...state.language,
          languageDropDownOpen: false
        }
      };
    case SET_ACTIVE_ACCOUNT_TAB:
      return {
        ...state,
        activeAccountTab: action.activeAccountTab
      };
    case SET_ACTIVE_SETTINGS_TAB:
      return {
        ...state,
        activeSettingsTab: action.activeSettingsTab
      };
    case SET_ACTIVE_MARKETING_TAB:
      return {
        ...state,
        activeMarketingTab: action.activeMarketingTab
      };
    default:
      return state;
  }
};
